<template lang="pug">
    div
        div(v-for="(item, idx) in survey" :key="idx")
            span
                p.bold.title {{ idx + 1 }}. {{ item.title }}
                    i.red *
                    div.answer {{item.value}}
</template>

<script>
export default {
  name: "survey_form",
  props: ["survey_string"],
  computed: {
    survey() {
      return JSON.parse(this.survey_string || "[]");
    }
  }
};
</script>

<style scoped>
.answer {
  margin-left: 10px;
}
.bold {
  font-weight: bold;
}
</style>
